import React from 'react';
import './navbar.css';

const Navbar = () => {
    return (
        <div className="fables-transparent py-3 py-lg-0">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 pr-md-0">
                        <nav className="navbar navbar-expand-md btco-hover-menu py-lg-2">

                            <a className="navbar-brand fables-logo-brand pl-0" href="/">
                                <img src="assets/custom/images/coralan-multiservice-logo.png" alt="Coralan Multiservice"
                                className="fables-logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#fablesNavDropdown" aria-controls="fablesNavDropdown"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="fables-iconmenu-icon text-primary font-16"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="fablesNavDropdown">

                                <ul className="navbar-nav mx-auto fables-nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#services">
                                            Servicios
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#whychooseus">
                                            ¿Porqué elegirnos?
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#aboutus">
                                            Nosotros
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#latestwork">
                                            Portafolio
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#ourplans">
                                            Precios
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#contactenos">
                                            Contactenos
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
