import React from 'react';
import './cta.css';

const Cta = () => {
    return (
        <div className="fables-testimonial fables-after-overlay py-5 my-5 bg-rules">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="fables-contact-caption-txt">
                            <h3 className="font-25 font-weight-bold white-color mb-3 position-relative z-index">
                                Eleva tu Presencia Digital con Nosotros!!
                            </h3>
                            <p className="fables-third-text-color position-relative z-index font-weight-light">
                                Potenciamos tu visión con soluciones de software personalizadas. Desde aplicaciones móviles innovadoras hasta plataformas web robustas, creamos la tecnología que impulsa tu éxito.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 offset-xl-2 col-xl-2 text-center">
                        <a href="#contactenos"
                           className="btn fables-second-background-color fables-btn-rounded white-color mt-3 position-relative z-index font-19 px-5 py-2 white-color-hover">
                            Contáctenos
                        </a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Cta;
