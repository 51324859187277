import React, { useState } from 'react';
import Service from '../../components/service/Service';

import './services.css';

const Services = () => {
    const [services] = useState([
        {
            icon: 'fables-icondevelopment-icon fables-second-text-color fa-3x',
            title: 'Desarrollo Web',
            description: 'Optimiza tu presencia online con potentes sitios web construidos en Drupal, Wordpress o Javascript.'
        },
        {
            icon: 'fables-iconmobileApp-icon fables-second-text-color fa-3x',
            title: 'Desarrollo de App Móviles',
            description: 'Transforma tu visión en realidad con nuestro servicio de desarrollo de aplicaciones móviles a la medida de su negocio.'
        },
        {
            icon: 'fables-iconcart fables-second-text-color fa-3x',
            title: 'Eleva tus ventas',
            description: 'Expande tu negocio y aumenta tus ingresos de manera exponencial con nuestras soluciones de eCommerce.'
        },
        {
            icon: 'fables-iconbussiness3 fables-second-text-color fa-3x',
            title: 'Software as a Service (SaaS)',
            description: 'Creamos interfaces que permiten la integración con otras aplicaciones, mejorando la conectividad y la funcionalidad de tu Web o App Móvil.'
        },
        {
            icon: 'fables-iconbussiness4 fables-second-text-color fa-3x',
            title: 'Facebook / Google Ads',
            description: 'Somos expertos en campañas publicitarias en Facebook o Google, optimizamos su presupuesto para obtener muchos mas clientes potenciales.'
        },
        {
            icon: 'fables-iconbussiness2 fables-second-text-color fa-3x',
            title: 'Mail marketing',
            description: 'Alcance masivo con mensajes personalizados para fortalecer la conexión con tu audiencia.'
        },
        {
            icon: 'fables-iconlamp-icon fables-second-text-color fa-3x',
            title: 'Diseño',
            description: 'Nuestro equipo de diseño crea experiencias a la medida para su web, app móvil o social media.'
        },
        {
            icon: 'fables-iconeye fables-second-text-color fa-3x',
            title: 'SEO: Visibilidad Online',
            description: 'Optimiza tu presencia online con nuestras estrategias SEO probadas.'
        }
    ])


    return (
        <div className="container" id="services">
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                    <h2 className="fables-second-text-color font-35 font-weight-bold my-3 mt-md-5 mb-md-4">
                        Nuestros servicios
                    </h2>
                </div>
            </div>
            <div className="row my-3 my-md-5 overflow-hidden">
                {services.map((service, index) => {
                    return (
                        <Service
                            key={index}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                        />
                    );
                })}
            </div>
        </div>
    )
};

export default Services;
