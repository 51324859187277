import React, {useEffect, useState} from 'react';
import {getFirestore, collection, query, getDocs, onSnapshot } from  'firebase/firestore';
import '../../database/firebase';
import LatestworkItem from "../../components/latestworkitem/LatestworkItem";

import './latestwork.css';

const Latestwork = () => {

    const [data, setData] = useState([]);

    useEffect(() => {

        const db = getFirestore();
        const colRef = collection(db, 'amunoz-portfolio');
        const q = query(colRef);
        const unsub = onSnapshot(q, (snapshot) => {
            let documents = [];
            snapshot.forEach((portfolio) => {
                documents.push({ id: portfolio.id, ...portfolio.data() })
            });
            setData(documents);
        });
        return () => unsub();

    }, []);

    return (
        <div className="container mb-4 mb-md-5" id="latestwork">
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mb-5">
                    <div className="text-center">
                        <h2 className="fables-main-text-color font-35 font-weight-bold mb-4">Trabajos mas recientes</h2>
                        <p className="fables-forth-text-color">
                            Proyectos realizados en los últimos 5 años. Destacar que en nuestro catálogo de servicios realizamos trabajos que no se muestran al público, como por ejemplo: Base de datos, servicios en la nube (AWS o Google Cloud), Mail Marketing, SEO, entre otros.
                        </p>
                    </div>
                </div>
            </div>

            <div className="gallery-filter">
                <div className="portfolioCContainer row filterr-masonry">
                    {data.map((dat, index) => {
                        return (
                            <LatestworkItem
                                key={index}
                                category={dat.category}
                                image={dat.image}
                                url={dat.url}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Latestwork;
