import React, {useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';

import './contactform.css';
const ContactForm = () => {

    useEffect(() => emailjs.init("OR-hFrEcD28bt5k1j"), []);
    const emailRef = useRef();
    const nameRef = useRef();
    const phoneRef = useRef();
    const serviceRef = useRef();
    const planRef = useRef();
    const [message, setMessage] = useState('');
    const [selected, setSelected] = useState();
    const bgHeaderImg = 'assets/custom/images/history-slider.jpg';

    const handleClick = radio => event => setSelected(radio);
    const sendEmail = async (e) => {
        e.preventDefault()
        const serviceId = "service_yh80bao";
        const templateId = "template_few544q";

        let msg = selected ? selected : 'Preguntar al cliente.';

        try {
            setMessage('Enviando mensaje...');
            await emailjs.send(serviceId, templateId, {
                fullName: nameRef.current.value,
                email: emailRef.current.value,
                phone_number: phoneRef.current.value,
                selectedService: msg,
                selectedPlan: planRef.current.value
            });
        } catch (error) {
            setMessage('Ha ocurrido un error, intente mas tarde o escriba al whatsapp 809-605-0699.');
        } finally {
            setMessage('El mensaje se ha enviado correctamente.');
        }
    };
    return (
        <div className="container mt-5 mb-6" id="contactenos">
            <div className="row">
                <div className="col-sm-5 contact-bg-image d-flex flex-column aling-center justify-content-center" style={{backgroundImage: `url(${bgHeaderImg})`}}>
                    <div className="d-flex flex-row">
                        <div className="p-2">
                            <span className="fables-iconmap-icon fables-white-text-color fa-1x"></span>
                        </div>
                        <div className="col-sm-8 p-2 text-white mb-4">
                            <h5 className="mb-2">Dirección:</h5>
                            <p className="c-text-muted">
                                Gustavo M Ricart #17, Edificio Ginaka. Las Praderas, Santo Domingo, Distrito Nacional. R.D.
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="p-2">
                            <span className="fables-iconheadset-icon fables-white-text-color fa-1x"></span>
                        </div>
                        <div className="col-sm-8 p-2 text-white mb-4">
                            <h5 className="mb-2">Hablemos:</h5>
                            <p className="c-text-muted">
                                Whatsapp: +1 (809) 605-0699
                            </p>
                            <p className="c-text-muted">
                                Oficina: +1 (809) 334-0405
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="p-2">
                            <span className="fables-iconemail fables-white-text-color fa-1x"></span>
                        </div>
                        <div className="col-sm-8 p-2 text-white mb-4">
                            <h5 className="mb-2">Correo:</h5>
                            <p className="c-text-muted">
                                info@coralanmultiservice.com
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-7">
                    <h2 className="mb-3 mt-4 mt-md-0">¿Cómo podemos ayudarle?</h2>
                    <form onSubmit={sendEmail} className="contactForm">
                        <div className="row">
                            <div className="mb-3 col-12 col-md-6">
                                <label className="form-label" htmlFor="name">
                                    Nombre y Apellido
                                </label>
                                <input name="fullName" className="form-control" ref={nameRef} required />
                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <label className="form-label" htmlFor="email">
                                    Correo
                                </label>
                                <input name="email" className="form-control" ref={emailRef} required />
                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <label className="form-label" htmlFor="phone">
                                    Teléfono/Whatsapp
                                </label>
                                <input name="phone_number" className="form-control" ref={phoneRef} type="phone" id="phone" required />
                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <label className="form-label" htmlFor="plan">
                                    Plan deseado
                                </label>
                                <div className="row">
                                    <div className="col-12">
                                        <select name="selectedPlan" ref={planRef} className="form-control">
                                            <option value="Inicial">Inicial</option>
                                            <option value="Avanzado">Avanzado</option>
                                            <option value="Premium">Premium</option>
                                            <option value="Ultra">Ultra</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 col-12">
                                <label className="form-label" htmlFor="message">
                                    ¿Cuál servicio le interesa?
                                </label>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption1" type="radio" onClick={handleClick('Website')} name="selectedService" ref={serviceRef} value="Website" />
                                            Página web
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption2" type="radio" onClick={handleClick('AppMobile')} name="selectedService" ref={serviceRef} value="AppMobile" />
                                            Aplicación móvil
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption3" type="radio" onClick={handleClick('SEO')} name="selectedService" ref={serviceRef} value="SEO" />
                                            Optimización SEO
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption4" type="radio" onClick={handleClick('Ads')} name="selectedService" ref={serviceRef} value="Ads" />
                                            FB / Google Ads
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption5" type="radio" onClick={handleClick('MailMarketing')} name="selectedService" ref={serviceRef} value="MailMarketing" />
                                            Mail Marketing
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption6" type="radio" onClick={handleClick('Consultoría Drupal')} name="selectedService" ref={serviceRef} value="Consultoría Drupal" />
                                            Consultoría Drupal
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption7" type="radio" onClick={handleClick('Actualizaciones')} name="selectedService" ref={serviceRef} value="Actualizaciones" />
                                            Actualizaciones
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption8" type="radio" onClick={handleClick('Manejo de redes')} name="selectedService" ref={serviceRef} value="Manejo de redes" />
                                            Manejo de Redes
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label className="desiredService">
                                            <input id="serviceOption9" type="radio" onClick={handleClick('Tienda en línea')} name="selectedService" ref={serviceRef} value="Tienda en línea" />
                                            Tienda en línea
                                            <span className="fables-iconarrow-next fables-main-text-color fa-1x"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-primary" disabled={message}>
                            Enviar
                        </button>
                        {message}
                    </form>
                </div>
            </div>

        </div>
    );
};

export default ContactForm;
