import React from 'react';

const Ourpricing = () => {
    return (
        <div id="ourplans" className="fables-price-bg fables-after-overlay py-4 py-lg-5 my-4 my-lg-5 bg-rules overflow-hidden">
            <div className="container position-relative z-index">
                <h2 className="white-color font-35 font-weight-bold text-center mb-4 mb-md-5">Nuestros planes</h2>
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3 text-center mb-4 mb-lg-0 wow bounceInDown"
                         data-wow-delay=".4s" data-wow-duration="1.5s">
                        <div className="table-border-light fables-second-hover-border colored-table">
                            <div className="bg-blue py-4">
                                <h2 className="white-color font-weight-bold position-relative fables-third-after font-30 table-title">Inicial</h2>
                                <h2 className="white-color font-weight-bold font-35 position-relative mt-4 d-inline-block">3,800 <span
                                    className="table-badge white-color position-absolute">RD$</span></h2>
                                <p className="white-color font-13 mb-4">Por mes</p>
                                <span
                                    className="fables-table-arrow border-2 bg-blue white-color rounded-circle border-white position-absolute"><i
                                    className="fas fa-arrow-down"></i></span>
                            </div>
                            <div className="bg-white py-4">
                                <ul className="fables-forth-text-color my-4 px-4 line-height-large font-15">
                                    <li>Página web estática</li>
                                    <li>Formulario de contacto</li>
                                    <li>www.MiEmpresa.com</li>
                                    <li>Alojamiento</li>
                                    <li>Correos @MiEmpresa.com</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 text-center mb-4 mb-lg-0 wow bounceInDown"
                         data-wow-delay=".8s" data-wow-duration="1.5s">
                        <div className="table-border-light fables-second-hover-border colored-table">
                            <div className="fables-second-background-color py-4">
                                <h2 className="white-color font-weight-bold position-relative fables-third-after font-30 table-title">Avanzado</h2>
                                <h2 className="white-color font-weight-bold font-35 position-relative mt-4 d-inline-block">5,990 <span
                                    className="table-badge white-color position-absolute">RD$</span></h2>
                                <p className="white-color font-13 mb-4">Por mes</p>
                                <span
                                    className="fables-table-arrow border-2 fables-second-background-color white-color rounded-circle border-white position-absolute"><i
                                    className="fas fa-arrow-down"></i></span>
                            </div>
                            <div className="bg-white py-4">
                                <ul className="fables-forth-text-color my-4 px-4 line-height-large font-15">
                                    <li>Plan Básico</li>
                                    <li>Formulario dinámico</li>
                                    <li>Sitio web dinámico</li>
                                    <li>Blog o Noticias</li>
                                    <li>Galería de fotos</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 text-center mb-4 mb-lg-0 wow bounceInDown"
                         data-wow-delay="1.2s" data-wow-duration="1.5s">
                        <div className="table-border-light fables-second-hover-border colored-table">
                            <div className="bg-primary py-4">
                                <h2 className="white-color font-weight-bold position-relative fables-third-after font-30 table-title">Premium</h2>
                                <h2 className="white-color font-weight-bold font-35 position-relative mt-4 d-inline-block">8,990 <span
                                    className="table-badge white-color position-absolute">RD$</span></h2>
                                <p className="white-color font-13 mb-4">Por mes</p>
                                <span
                                    className="fables-table-arrow border-2 bg-primary white-color rounded-circle border-white position-absolute"><i
                                    className="fas fa-arrow-down"></i></span>
                            </div>
                            <div className="bg-white py-4">
                                <ul className="fables-forth-text-color my-4 px-4 line-height-large font-15">
                                    <li>Plan Avanzado</li>
                                    <li>Desarrollo a medida</li>
                                    <li>Drupal / Wordpress</li>
                                    <li>Manejo de contenido</li>
                                    <li>Diseño a medida</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 text-center mb-4 mb-lg-0 wow bounceInDown"
                         data-wow-delay="1.6s" data-wow-duration="1.5s">
                        <div className="table-border-light fables-second-hover-border colored-table">
                            <div className="fables-main-background-color py-4">
                                <h2 className="white-color font-weight-bold position-relative fables-third-after font-30 table-title">Ultra</h2>
                                <h2 className="white-color font-weight-bold font-35 position-relative mt-4 d-inline-block">12,990<span
                                    className="table-badge white-color position-absolute">RD$</span></h2>
                                <p className="white-color font-13 mb-4">Por mes</p>
                                <span
                                    className="fables-table-arrow border-2 fables-main-background-color white-color rounded-circle border-white position-absolute"><i
                                    className="fas fa-arrow-down"></i></span>
                            </div>
                            <div className="bg-white py-4">
                                <ul className="fables-forth-text-color my-4 px-4 line-height-large font-15">
                                    <li>Plan Premium</li>
                                    <li>Integración de servicios</li>
                                    <li>E-Commerce</li>
                                    <li>Manejo de productos</li>
                                    <li>Diseño a medida</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
};

export default Ourpricing;
