import React from 'react';
import './whychooseus.css';

const Whychooseus = () => {
    return (
        <div
            className="fables-choose-background fables-after-overlay py-4 py-md-5 bg-rules overflow-hidden px-3 px-md-0" id="whychooseus">
            <div className="container position-relative z-index">
                <div className="row">
                    <div
                        className="col-12 col-lg-6 p-0 image-container translate-effect-right wow fadeInLeft mb-3 mb-md-0"
                        data-wow-delay="1s" data-wow-duration="1.5s">
                        <img src="assets/custom/images/choose-img.jpg" alt="" className="w-100" />
                    </div>
                    <div className="col-12 col-lg-6 bg-white px-6 py-3 py-md-5 wow fadeInRight" data-wow-delay="1s"
                         data-wow-duration="1.5s">
                        <h2 className="font-30 font-weight-bold fables-second-text-color">¿Porqué Elegirnos?</h2>
                        <p className="fables-main-text-color font-14 my-3">
                            Tenemos mas de 15 años de experiencia en el desarrollo web, nuestro equipo está comprometido con el éxito del cliente, trabajamos de cerca para
                            comprender sus metas y desafíos.
                        </p>
                        <div id="accordion">
                            <div className="card border-0 mb-2">
                                <div className="card-header p-0 border bg-transparent rounded-0" id="headingOne">
                                    <h5 className="mb-0 position-relative">
                                <span className="fables-second-background-color white-color d-inline-block
 position-absolute fables-lus-icon pt-2 text-center">
                                    <i className="fas fa-plus"></i>
                                </span>
                                        <button
                                            className="btn fables-main-text-color bg-transparent font-18 focus-0 d-block position-relative z-index pl-6 pt-2 pb-2 w-100 text-left border-0 text-truncate"
                                            data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Experiencia Integral
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                     data-parent="#accordion">
                                    <div className="card-body fables-forth-text-color font-14 py-1 py-xl-2 pl-6 pr-0">
                                        Desde el desarrollo de software hasta el diseño y la optimización SEO, ofrecemos una experiencia integral. Al elegirnos, los clientes obtienen soluciones cohesivas y sin fisuras, ahorrando tiempo y esfuerzo al tener todos sus servicios digitales bajo un mismo techo.
                                    </div>
                                </div>
                            </div>
                            <div className="card border-0 mb-2">
                                <div className="card-header bg-transparent p-0 border rounded-0" id="headingTwo">
                                    <h5 className="mb-0 position-relative">
                                <span className="fables-second-background-color white-color d-inline-block
 position-absolute fables-lus-icon pt-2 text-center">
                                    <i className="fas fa-plus"></i>
                                </span>
                                        <button
                                            className="btn fables-main-text-color bg-transparent font-18 focus-0 d-block position-relative z-index pl-6 pt-2 pb-2 w-100 text-left border-0 text-truncate collapsed"
                                            data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            Innovación Constante
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                     data-parent="#accordion">
                                    <div className="card-body fables-forth-text-color font-14 py-1 py-xl-2 pl-6 pr-0">
                                        Nos mantenemos a la vanguardia de las últimas tecnologías y tendencias digitales. Con un enfoque constante en la innovación, aseguramos que nuestros clientes siempre cuenten con las herramientas más avanzadas y efectivas para destacar en el competitivo mundo online.
                                    </div>
                                </div>
                            </div>
                            <div className="card border-0 mb-2">
                                <div className="card-header p-0 border bg-transparent rounded-0" id="headingThree">
                                    <h5 className="mb-0 position-relative">
                                <span className="fables-second-background-color white-color d-inline-block
 position-absolute fables-lus-icon pt-2 text-center">
                                    <i className="fas fa-plus"></i>
                                </span>
                                        <button
                                            className="btn fables-main-text-color bg-transparent font-18 focus-0 d-block position-relative z-index pl-6 pt-2 pb-2 w-100 text-left border-0 text-truncate collapsed"
                                            data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            Equipo Expertise Multidisciplinario
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                     data-parent="#accordion">
                                    <div className="card-body fables-forth-text-color font-14 py-1 py-xl-2 pl-6 pr-0">
                                        Nuestro equipo está compuesto por expertos en desarrollo web, diseño UX/UI, SEO, y más. La diversidad de habilidades nos permite abordar cualquier desafío con enfoques creativos y soluciones efectivas, garantizando un resultado que supere las expectativas.
                                    </div>
                                </div>
                            </div>
                            <div className="card border-0 mb-2">
                                <div className="card-header p-0 border bg-transparent rounded-0" id="headingfour">
                                    <h5 className="mb-0 position-relative">
                                <span className="fables-second-background-color white-color d-inline-block
 position-absolute fables-lus-icon pt-2 text-center">
                                    <i className="fas fa-plus"></i>
                                </span>
                                        <button
                                            className="btn fables-main-text-color bg-transparent font-18 focus-0 d-block position-relative z-index pl-6 pt-2 pb-2 w-100 text-left border-0 text-truncate collapsed"
                                            data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            Compromiso con el Éxito del Cliente
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingfour"
                                     data-parent="#accordion">
                                    <div className="card-body fables-forth-text-color font-14 py-1 py-xl-2 pl-6 pr-0">
                                        No solo construimos sitios web, creamos asociaciones exitosas. Estamos comprometidos con el éxito de nuestros clientes y trabajamos de cerca con ellos para comprender sus metas y desafíos. Nuestra dedicación va más allá de la entrega del proyecto, asegurando un apoyo continuo para el crecimiento sostenible de su presencia digital.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default Whychooseus;
