import React from 'react';
import './header.css';

const Header = () => {
    const bgHeaderImg = 'assets/custom/images/index-background.jpg';

    return (
        <div className="fables-header fables-after-overlay overlay-lighter index-traingle bg-rules" style={{backgroundImage: `url(${bgHeaderImg})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-lg-7 mr-auto index-carousel">
                        <div className="owl-carousel owl-theme default-carousel nav-0 z-index mt-md-4 mt-xl-5 pt-md-4 pt-xl-5 dots-0 pb-md-5">
                            <div className="pt-0 mt-0 pt-xl-5 mt-xl-5 wow slideInUp" data-wow-duration="2s" data-wow-delay=".4s">
                                <h1 className="fables-main-text-color font-weight-bold mb-1">DESARROLLO DE SOFTWARE A LA
                                    <span className="fables-second-text-color">MEDIDA DE SU NEGOCIO</span>
                                </h1>
                                <p className="fables-forth-text-colo mb-3 light-font fables-header-slider-details">
                                    Potenciamos tu visión con soluciones de software personalizadas. Desde aplicaciones móviles innovadoras hasta plataformas web robustas, creamos la tecnología que impulsa tu éxito.
                                </p>
                                <a href="#contactenos" className="btn fables-second-background-color fables-second-border-color white-color rounded-0 mr-4 px-3 px-md-4 py-2 bg-hover-transparent fables-second-hover-color">Contáctenos</a>
                            </div>
                            <div className="pt-0 mt-0 pt-xl-5 mt-xl-5 wow slideInUp" data-wow-duration="2s" data-wow-delay=".8s">
                                <h1 className="fables-main-text-color font-weight-bold mb-1">DESARROLLO DE SOFTWARE EN
                                    <span className="fables-second-text-color">GENERAL CON DRUPAL</span>
                                </h1>
                                <p className="fables-forth-text-colo mb-3 light-font fables-header-slider-details">
                                    Optimiza tu presencia online con potentes sitios web construidos en Drupal. Ofrecemos personalización, escalabilidad y rendimiento excepcionales.
                                </p>
                                <a href="#contactenos" className="btn fables-second-background-color fables-second-border-color white-color rounded-0 mr-4 px-3 px-md-4 py-2 bg-hover-transparent fables-second-hover-color">Contáctenos</a>
                            </div>
                            <div className="pt-0 mt-0 pt-xl-5 mt-xl-5 wow slideInUp" data-wow-duration="2s" data-wow-delay=".12s">
                                <h1 className="fables-main-text-color font-weight-bold mb-1">SOLUCIONES INFORMÁTICAS
                                    <span className="fables-second-text-color">INTEGRADAS</span>
                                </h1>
                                <p className="fables-forth-text-colo mb-3 light-font fables-header-slider-details">
                                    Simplifica tus operaciones con nuestras soluciones informáticas. Automatización, gestión eficiente de datos y sistemas que trabajan en armonía para potenciar tu rendimiento.
                                </p>
                                <a href="#contactenos" className="btn fables-second-background-color fables-second-border-color white-color rounded-0 mr-4 px-3 px-md-4 py-2 bg-hover-transparent fables-second-hover-color">Contáctenos</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
