import React from 'react';

const Service = (props) => {
    return (
        <div className="col-12 col-sm-6 col-lg-3 text-center mb-4 mb-sm-5 wow fadeInDown" data-wow-delay=".4s" data-wow-duration="1.5s">
            <span className={props.icon}></span>
            <h2 className="fables-main-text-color font-18 my-2">
                {props.title}
            </h2>
            <p className="fables-forth-text-color font-15">
                {props.description}
            </p>
        </div>
    );
};

export default Service;
