import React from 'react';
import './ourexperience.css';
const Ourexperience = () => {
    return (
        <div className="container" id="aboutus">
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                    <h2 className="fables-second-text-color font-35 font-weight-bold my-3 mt-md-5 mb-md-4">Experiencia, Innovación, Calidad y Compromiso</h2>
                    <p className="fables-forth-text-color">
                        Desde el desarrollo de software hasta el diseño y la optimización SEO, ofrecemos una experiencia integral.
                        Nos mantenemos a la vanguardia de las últimas tecnologías y tendencias digitales.
                        Nuestro equipo está compuesto por expertos en desarrollo web/móvil, diseño UX/UI, SEO, y más.
                        Nuestra dedicación va más allá de la entrega del proyecto, asegurando un apoyo continuo para el crecimiento sostenible de su presencia digital.
                    </p>
                </div>
            </div>
            <div className="row mt-4 mt-md-5">
                <div className="col-6 col-md-3">
                    <div className="fables-counter">
                        <h2 className="fables-counter-value font-40 font-weight-bold mb-3 fables-main-text-color border fables-second-border-color d-inline-block px-4 py-2 mb-4"
                            data-count="30">0</h2>
                        <h3 className="font-14 semi-font fables-forth-text-color">PROYECTOS REALIZADOS</h3>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="fables-counter">
                        <h2 className="fables-counter-value font-40 font-weight-bold mb-3 fables-main-text-color border fables-second-border-color d-inline-block px-4 py-2 mb-4"
                            data-count="10">0</h2>
                        <h3 className="font-14 semi-font fables-forth-text-color">EMPRESAS LOCALES</h3>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="fables-counter">
                        <h2 className="fables-counter-value font-40 font-weight-bold mb-3 fables-main-text-color border fables-second-border-color d-inline-block px-4 py-2 mb-4"
                            data-count="4">0</h2>
                        <h3 className="font-14 semi-font fables-forth-text-color">EMPRESAS INTERNACIONALES</h3>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="fables-counter">
                        <h2 className="fables-counter-value font-40 font-weight-bold mb-3 fables-main-text-color border fables-second-border-color d-inline-block px-4 py-2 mb-4"
                            data-count="15">0</h2>
                        <h3 className="font-14 semi-font fables-forth-text-color">AÑOS DE EXPERIENCIA</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ourexperience;
