import React from 'react';

import { Header, Footer, Services, Ourexperience, Whychooseus, Ourpricing, Latestwork } from './containers';
import { Navbar, Cta } from "./components";
import './App.css';
import ContactForm from "./components/contactform/ContactForm";

const App = () => {
    return (
        <div className="App">
            <Navbar />
            <Header />
            <Services />
            <Whychooseus />
            <Ourexperience />
            <Cta />
            <Latestwork />
            <Ourpricing />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default App
