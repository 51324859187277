import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <div className="fables-footer-image fables-after-overlay white-color py-4 py-lg-5 bg-rules">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                        <a href="#"
                           className="pb-3 d-block mb-3"><img className="logo-white"
                            src="assets/custom/images/logo-coralan-white.png" alt="Coralan Multiservice" /></a>
                        <p className="font-15 fables-third-text-color mb-4">
                            Somos una empresa de soluciones digitales especializada en ayudar a las empresas
                            a tener presencia en internet, nuestro objetivo principal consiste en que su empresa sea
                            vista en todo internet y así ayudarle a aumentar las ventas y credibilidad de sus clientes.
                        </p>
                        <p className="mb-0">RNC: 1-32-03635-2</p>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <h2 className="font-20 semi-font fables-second-border-color border-bottom pb-3">Contáctenos</h2>
                        <div className="my-3">
                            <h4 className="font-16 semi-font"><span
                                className="fables-iconmap-icon fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Dirección</h4>
                            <p className="font-14 fables-fifth-text-color mt-2 ml-4">Ave. Gustavo M. Ricart, #17, Las Praderas, Santo Domingo.</p>
                        </div>
                        <div className="my-3">
                            <h4 className="font-16 semi-font"><span
                                className="fables-iconphone fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Whatsapp/Teléfono </h4>
                            <p className="font-14 fables-fifth-text-color mt-2 ml-4">+1 (809) 605-0699</p>
                        </div>
                        <div className="my-3">
                            <h4 className="font-16 semi-font"><span
                                className="fables-iconemail fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Correo
                            </h4>
                            <p className="font-14 fables-fifth-text-color mt-2 ml-4">hablemos@coralanmultiservice.com</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                        <h2 className="font-20 semi-font fables-second-border-color border-bottom pb-3 mb-3">Conocer más</h2>
                        <ul className="nav fables-footer-links">
                            <li><a href="#services">Servicios</a></li>
                            <li><a href="#whchooseus">¿Porqué Elegirnos?</a></li>
                            <li><a href="#aboutus">Acerca de Nosotros</a></li>
                            <li><a href="#latestwork">Trabajos Recientes</a></li>
                            <li><a href="#ourplans">Precios</a></li>
                            <li><a href="#contactenos">Contáctenos</a></li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default Footer;
