import React from 'react';

const LatestworkItem = (props) => {
    return (
        <div className={`${props.category} col-sm-6 col-md-3 mb-2`}>
            <div className="filter-img-block position-relative image-container translate-effect-right">
                <a href={props.url} target="_blank">
                    <img src={props.image} alt="image" className="w-100" />
                </a>
            </div>
        </div>
    );
};

export default LatestworkItem;
